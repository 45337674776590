import {Link } from "react-router-dom";
import React, { useEffect } from "react";
import $ from 'jquery'
import DataTable from 'datatables.net-dt';

// import '../../assets/js/jquery-3.6.0.min.js';
// import '../../assets/js/datatable/datatables/jquery.dataTables.min.js';

const OccupationList = () => {
    useEffect(() =>{
      // $('#basic-1').DataTable();
      let table = new DataTable('#basic-1');
    },[]);

  return (
   <>
   
   <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <div class="col-6">
                  <h3>Occupation Master</h3>
                </div>
                <div class="col-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="#!">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item">Home</li>
                    <li class="breadcrumb-item active">Occupation Master List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 project-list">
                  <div class="card">
                    <div class="row">
                      <div class="col-md-6 p-0 d-flex">
                        <h3 style={{fontSize:"18px", marginLeft:"10px"}}>Occupation Master List</h3>
                      </div>
                      <div class="col-md-6 p-0">                    
                        <div class="form-group mb-0 me-0"></div>
                        <div className="file-content">
                          <div class="d-md-flex d-sm-block text-end" style={{justifyContent:"right"}}>
                            <form class="form-inline" action="#!" method="get" style={{marginRight:"10px"}}>
                              <div class="form-group d-flex mb-0"><i class="fa fa-search"></i>
                                <input class="form-control-plaintext" type="text" placeholder="Search..." data-bs-original-title="" title="" />
                              </div>
                            </form>
                            <div style={{marginTop:"4px"}}>
                              <Link to={"../occupation/create"} className="btn btn-primary" style={{marginRight: "10px"}}>
                                <i className="fa fa-solid fa-plus"></i>
                              </Link>
                              <a href="#!" className="btn btn-primary" style={{marginRight: "10px"}}>
                                <i className="fa fa-file-excel-o"></i>
                              </a>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-3"> */}
                          {/* <input type="search" className="form-control" placeholder=""  style={{marginRight: "10px"}}></input> */}
                        {/* </div> */}

                        {/* <Link to={"../occupation/create"} className="btn btn-primary" style={{marginRight: "10px"}}>
                          <i className="fa fa-file-excel-o"></i>
                        </Link>
                        <Link to={"../occupation/create"} className="btn btn-primary" style={{marginRight: "10px"}}>
                          <i className="fa fa-solid fa-plus"></i>
                        </Link> */}
                      </div>
                    </div>
                  </div>
              </div>
              
              <div class="col-sm-12">
                <div class="card">
                  {/* <div class="card-header pb-0">
                    <h3>Occupation Master List</h3>
                  </div> */}
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="display" id="basic-1">
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Master Name</th>
                            <th>Name</th>
                            <th>Is Active?</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Occupation Master</td>
                            <td>Test 1</td>
                            <td><span class="badge rounded-pill badge-success">Active</span></td>
                            <td> 
                              <ul class="action"> 
                                <li class="edit"> <a href="#!"><i class="icon-pencil-alt"></i></a></li>
                                <li class="delete"><a href="#!"><i class="icon-trash"></i></a></li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Occupation Master</td>
                            <td>Test 2</td>
                            <td><span class="badge rounded-pill badge-danger">In-Active</span></td>
                            <td> 
                              <ul class="action"> 
                                <li class="edit"> <a href="#!"><i class="icon-pencil-alt"></i></a></li>
                                <li class="delete"><a href="#!"><i class="icon-trash"></i></a></li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
   </>
  );
};

export default OccupationList;