import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import Home from "./pages/Home";
import OccupationList from './pages/OccupationMaster/OccupationList';
import OccupationCreate from './pages/OccupationMaster/OccupationCreate';
// import $ from './assets/js/jquery-3.6.0.min.js';

function App() {

  return (
    <BrowserRouter>
    <Routes>
      {/* <Route index element={<Home />} /> */}
      <Route path="/" element={<Layout />}>
        <Route index element={<OccupationList />} />
        <Route path="occupation/create" element={<OccupationCreate />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
