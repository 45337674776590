import {Link } from "react-router-dom";

const OccupationCreate = () => {
  return (
   <>
   
   <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <div class="col-6">
                  <h3>
                     Occupation Master Create</h3>
                </div>
                <div class="col-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index-2.html">                                       <i data-feather="home"></i></a></li>
                    <li class="breadcrumb-item">Occupation Master</li>
                    <li class="breadcrumb-item active">Occupation Master Create                           </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 project-list">
                  <div class="card">
                    <div class="row">
                      <div class="col-md-6 p-0 d-flex">
                        <h3 style={{fontSize:"18px", marginLeft:"10px"}}>Occupation Master</h3>
                      </div>
                      <div class="col-md-6 p-0">                    
                        <div class="form-group mb-0 me-0"></div>
                        <div className="file-content">
                          <div class="d-md-flex d-sm-block text-end" style={{justifyContent:"right"}}>
                            <div style={{marginTop:"4px"}}>
                              <a href="#!" className="btn btn-primary" style={{marginRight: "10px"}}>
                                <i className="fa fa-solid fa-list"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="form theme-form">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label>Select Master</label>
                            <select class="form-select" disabled>
                              <option selected>Occupation Master</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label>Name</label>
                            <input class="form-control" type="text" placeholder="Enter name" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label>Active</label>
                            {/* <div class="flex-grow-1 text-end icon-state">
                                <label class="switch">
                                    <input type="checkbox" checked="" /><span class="switch-state bg-success"></span>
                                </label>
                            </div> */}
                            <div class="flex-grow-1">
                                <label class="switch">
                                    <input type="checkbox" checked/><span class="switch-state bg-success"></span>
                                </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                            <div class="text-end">
                                <a class="btn btn-success me-3" href="#!">Save</a>
                                <Link class="btn btn-danger" to={"../"}>Cancel</Link>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   </>
   );
 };
 
 export default OccupationCreate;