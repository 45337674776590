import { Outlet, NavLink } from "react-router-dom";
import React, {useRef, useEffect } from "react";
import Header from "./include/Header";
import LeftSide from "./include/LeftSide";
import {Link } from "react-router-dom";
import $ from 'jquery';


const Layout = () => {
  const childRef = useRef();
  const [isActive, setActive] = React.useState(true);
  const [isToggleSidebar, setToggleSidebar] = React.useState(false);
  const myRef = useRef();

  useEffect(() => {
    if(myRef.current.offsetWidth >= 768){
      setActive(false)
    }
  //     $(".toggle-sidebar").on('click', function() {
  //       $(".sidebar-wrapper").toggleClass("close_icon");
  //       $(".page-header").toggleClass("close_icon");
  //       $(window).trigger("overlay");
  //     });

  //     $(window).on("overlay", function () {
  //       var bgOverlay = $(".bg-overlay");
  //       var isHidden = $(".sidebar-wrapper").hasClass("close_icon");
  //       if ($(window).width() <= 991 && !isHidden && $(".bg-overlay").length === 0) {
  //         $('<div class="bg-overlay active"></div>').appendTo($("body"));
  //       }

  //       if (isHidden && $(".bg-overlay").length > 0) {
  //         $(".bg-overlay").remove();
  //       }
  //     });

  //     let sidebarWrapper = $(".sidebar-wrapper")
  //     $(".sidebar-wrapper .back-btn").on('click', function(e) {
  //       sidebarWrapper.toggleClass("close_icon");
  //       var isHidden = $(".sidebar-wrapper").hasClass("close_icon");
  //       console.log("back",isHidden);
  //       if(isHidden){
  //         $(".page-header").removeClass("close_icon");
  //         sidebarWrapper.removeClass("close_icon");
  //       }else{
  //         console.log("-inside-")
  //         $(".page-header").addClass("close_icon");
  //         sidebarWrapper.addClass("close_icon");
  //       }
  //       $(window).trigger("overlay");
  //     });

  //     $("body").on("click", ".bg-overlay", function () {
  //       $(".page-header").addClass("close_icon");
  //       $(".sidebar-wrapper").addClass("close_icon");
  //       $(this).remove();
  //     });

  //     var body_part_side = $(".body-part");
  //     body_part_side.on('click', function() {
  //       $(".toggle-sidebar").attr("checked", false);
  //       $(".sidebar-wrapper").addClass("close_icon");
  //       $(".page-header").addClass("close_icon");
  //     });
    }, []);

  const onToggleSidebar = () => {
    setActive(!isActive);
    console.log("ref", myRef.current.offsetWidth);
  }

  return (
    <>
    <div class="tap-top"><i data-feather="chevrons-up"></i></div>
    
    
    {/* <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
     */}
    
    <div class="page-wrapper compact-wrapper" id="pageWrapper">
      
      <div className={`page-header ${isActive ? "close_icon" : ""}`}>
        <div class="header-wrapper row m-0" ref={myRef}>
          <form class="form-inline search-full col" action="#" method="get">
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text" placeholder="Search Koho .." name="q" title="" autofocus />
                  <div class="spinner-border Typeahead-spinner" role="status"><span class="sr-only">Loading...</span></div><i class="close-search" data-feather="x"></i>
                </div>
                <div class="Typeahead-menu"></div>
              </div>
            </div>
          </form>
          <div class="header-logo-wrapper col-auto p-0">
            <div class="logo-wrapper"><a href="#!">LeadGraph</a></div>
            <div class="toggle-sidebar new1" onClick={() => onToggleSidebar()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-left status_toggle middle sidebar-toggle"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>
            </div>
          </div>
          <div class="left-header col horizontal-wrapper ps-0">
            <div class="input-group">     
              <input class="form-control" type="text" placeholder="Search Here........"/><span class="input-group-text mobile-search"><i data-feather="search"></i></span>
            </div>
          </div>
          <div class="nav-right col-6 pull-right right-header p-0">
            <ul class="nav-menus">
              <li class="onhover-dropdown">
                <i className="fa fa-car"></i>
                <ul class="chat-dropdown onhover-show-div">
                  <li>
                    {/* <i className="fa fa-car"></i> */}
                    <h3 class="mb-0">Showroom</h3>
                  </li>
                  <li class="text-center"> <a class="btn btn-primary" href="#!">View All     </a></li>
                </ul>
              </li>
              <li class="onhover-dropdown">
                <i class="fa fa-solid fa-gears"></i>
                <ul class="chat-dropdown onhover-show-div">
                  <li>
                    {/* <i className="fa fa-car"></i> */}
                    <h3 class="mb-0">Workshop</h3>
                  </li>
                  <li class="text-center"> <a class="btn btn-primary" href="#!">View All     </a></li>
                </ul>
              </li>
              <li class="onhover-dropdown">
              <i class="fa fa-solid fa-shield"></i>
                <ul class="chat-dropdown onhover-show-div">
                  <li>
                    {/* <i className="fa fa-car"></i> */}
                    <h3 class="mb-0">Findesk</h3>
                  </li>
                  <li class="text-center"> <a class="btn btn-primary" href="#!">View All     </a></li>
                </ul>
              </li>
              <li class="onhover-dropdown">
                <i class="fa fa-solid fa-camera"></i>
                <ul class="chat-dropdown onhover-show-div">
                  <li>
                    {/* <i className="fa fa-car"></i> */}
                    <h3 class="mb-0">CRM</h3>
                  </li>
                  <li class="text-center"> <a class="btn btn-primary" href="#!">View All     </a></li>
                </ul>
              </li>

              
              <li class="onhover-dropdown">
                <div class="notification-box">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
                  <span class="badge rounded-pill badge-primary">4</span></div>
                <ul class="notification-dropdown onhover-show-div">
                  <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
                    <h3 class="mb-0">Notifications</h3>
                  </li>
                  <li><a href="#!"> 
                      <p><i class="fa fa-circle-o me-3 font-primary"> </i>Delivery processing <span class="pull-right">10 min.</span></p></a></li>
                  <li><a href="#!">
                      <p><i class="fa fa-circle-o me-3 font-success"></i>Order Complete<span class="pull-right">1 hr</span></p></a></li>
                  <li><a href="#!">
                      <p><i class="fa fa-circle-o me-3 font-info"></i>Tickets Generated<span class="pull-right">3 hr</span></p></a></li>
                  <li><a href="#!">
                      <p><i class="fa fa-circle-o me-3 font-danger"></i>Delivery Complete<span class="pull-right">6 hr</span></p></a></li>
                  <li><a class="btn btn-primary" href="#!">Check all notification</a></li>
                </ul>
              </li>
              <li class="onhover-dropdown"><i class="fa fa-solid fa-comments"></i>
                <ul class="chat-dropdown onhover-show-div">
                  <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                    <h3 class="mb-0">Support</h3>
                  </li>
                  <li>
                    <div class="d-flex"><img class="img-fluid rounded-circle me-3" src="../assets/images/user/6.jpg" alt=""/>
                      <div class="status-circle online"></div>
                      <div class="flex-grow-1"><a href="user-#!"><span>Ain Chavez</span>
                          <p>Do you want to go see movie?</p></a></div>
                      <p class="f-12 font-success">2 mins ago</p>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex"><img class="img-fluid rounded-circle me-3" src="../assets/images/user/1.jpg" alt=""/>
                      <div class="status-circle online"></div>
                      <div class="flex-grow-1"><a href="#!"><span>Erica Hughes</span>
                          <p>Thank you for rating us.</p></a></div>
                      <p class="f-12 font-success">5 mins ago</p>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex"><img class="img-fluid rounded-circle me-3" src="../assets/images/user/7.jpg" alt=""/>
                      <div class="status-circle offline"></div>
                      <div class="flex-grow-1"><a href="#!"><span>Kori Thomas</span>
                          <p>What`s the project report update?</p></a></div>
                      <p class="f-12 font-danger">9 mins ago</p>
                    </div>
                  </li>
                  <li class="text-center"> <a class="btn btn-primary" href="#!">View All     </a></li>
                </ul>
              </li>
              <li class="profile-nav onhover-dropdown p-0 me-0">
                <div class="d-flex profile-media"><img class="b-r-50" src="../assets/images/dashboard/profile.png" alt=""/>
                  <div class="flex-grow-1"><span>Helen Walter</span>
                    <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p>
                  </div>
                </div>
                <ul class="profile-dropdown onhover-show-div">
                  <li><a href="#!"><i data-feather="user"></i><span>Account </span></a></li>
                  <li><a href="#!"><i data-feather="mail"></i><span>Inbox</span></a></li>
                  <li><a href="#!"><i data-feather="file-text"></i><span>Taskboard</span></a></li>
                  <li><a href="#!"><i data-feather="log-in"> </i><span>Log in</span></a></li>
                </ul>
              </li>
            </ul>
          </div>
          <script class="result-template" type="text/x-handlebars-template">
            <div class="ProfileCard u-cf">                        
            <div class="ProfileCard-avatar"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path><polygon points="12 15 17 21 7 21 12 15"></polygon></svg></div>
            <div class="ProfileCard-details">
            <div class="ProfileCard-realName">name</div>
            </div>
            </div>
          </script>
          <script class="empty-template" type="text/x-handlebars-template"><div class="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div></script>
        </div>
      </div>
      
      
      <div class="page-body-wrapper">
        
        <div className={`sidebar-wrapper ${isActive ? "close_icon" : ""}`}>
          <div>
            <div class="logo-wrapper"><a href="#!">
              LeadGraph
              {/* <img class="img-fluid for-light" src="../assets/images/logo/logo.png" alt=""/>
              <img class="img-fluid for-dark" src="../assets/images/logo/logo-dark.png" alt=""/> */}
              </a>
              <div class="back-btn" onClick={() => onToggleSidebar()}><i class="fa fa-angle-left"></i></div>
              <div class="toggle-sidebar new2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-left status_toggle middle sidebar-toggle"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>
              </div>
            </div>
            <div class="logo-icon-wrapper"><a href="#!"><img class="img-fluid for-light" src="../assets/images/logo/logo-icon.png" alt=""/><img class="img-fluid for-dark" src="../assets/images/logo/logo-icon-dark.png" alt=""/></a></div>
            <nav class="sidebar-main">
              <div class="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
              <div id="sidebar-menu">
                <ul class="sidebar-links" id="simple-bar" data-simplebar="init"><div class="simplebar-wrapper" ><div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask"><div class="simplebar-offset" ><div class="simplebar-content-wrapper"><div class="simplebar-content" >
                  <li class="back-btn"><a href="#!"><img class="img-fluid for-light" src="../assets/images/logo/logo-icon.png" alt=""/><img class="img-fluid for-dark" src="../assets/images/logo/logo-icon-dark.png" alt=""/></a>
                    <div class="mobile-back text-end"><span>Back</span><i class="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
                  </li>
                  <li class="sidebar-main-title">  
                    <div>       
                      <h4 class="lan-1">General</h4>
                    </div>
                  </li>
                  
                  {/* <li class="sidebar-list"><a class="sidebar-link sidebar-title" href="javascript:void(0)"><i data-feather="airplay"></i><span class="lan-6">Widgets</span></a>
                    <ul class="sidebar-submenu">
                      <li><a href="general-widget.html">General</a></li>
                      <li><a href="chart-widget.html">Chart</a></li>
                    </ul>
                  </li> */}

                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Occupation Master</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                    <ul class="sidebar-submenu" >
                      <li><Link to={"../"}>List</Link></li>
                      <li><Link to={"../occupation/create"}>Create</Link></li>
                    </ul>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Lead Status Master</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Brand Master</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Relation Master</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Lead Source Master</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">City Area</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Competition Product</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Lead Cost Center</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Lead Nature</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  <li class="sidebar-list">
                    <a class="sidebar-link sidebar-title" href="#!">
                      <span class="lan-67">Lead Customer Type</span><div class="according-menu"><i class="fa fa-angle-right"></i></div>
                    </a>
                  </li>
                  {/* <li class="sidebar-list"><a class="sidebar-link sidebar-title link-nav" href="#!"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg><span>Support Ticket      </span><div class="according-menu"><i class="fa fa-angle-right"></i></div></a></li> */}
                </div></div></div></div><div class="simplebar-placeholder"></div></div><div class="simplebar-track simplebar-horizontal" ><div class="simplebar-scrollbar"></div></div><div class="simplebar-track simplebar-vertical"><div class="simplebar-scrollbar" ></div></div></ul>
                <div class="sidebar-img-section">
                  <div class="sidebar-img-content">
                    <img class="img-fluid" src="../assets/images/dashboard/upgrade/2.png" alt=""/>
                    <h4>Experiance with more Features</h4><a class="btn btn-primary" href="#!" >Check now</a>
                  </div>
                </div>
              </div>
              <div class="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
            </nav>
          </div>
        </div>
        
        <div class="page-body">
          <Outlet></Outlet>
        </div>
        
        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-10 p-0 footer-left">
                <p class="mb-0">Copyright 2024 © LeadGraph</p>
              </div>
              <div class="col-2 p-0 footer-right">     <i class="fa fa-heart font-danger">               </i></div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    </>
  )
};

export default Layout;